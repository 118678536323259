import {PermissionType} from '@hconnect/apiclient/src/types'
import {useMemo} from 'react'

import {usePermissionsQuery} from '../../hooks/api'
import {checkPermissionAccessByCountryOrPlantId} from '../../permissions'
import {usePlantConfig} from '../usePlantConfigData'
import {useUrlParam} from '../useUrlParam'

enum MachinePlanPermissionTypes {
  ViewMachinePlan = 'VIEW_MACHINE_PLAN',
  ChangeMachinePlan = 'CHANGE_MACHINE_PLAN'
}

/**
 * @deprecated
 */

export const useMachinePlanPermissions = () => {
  const {data: permissions} = usePermissionsQuery()
  const latest = usePlantConfig()

  const plantCode = useUrlParam('plantCode')
  const countryId = latest.country

  return useMemo(() => {
    const machinePlanPermissions = (permissions || []).filter(
      ({permissionType, parsedDataScope}) => {
        const changeMachinePlanDataScope = parsedDataScope
        return (
          (
            [
              MachinePlanPermissionTypes.ViewMachinePlan,
              MachinePlanPermissionTypes.ChangeMachinePlan
            ] as PermissionType[]
          ).includes(permissionType) &&
          checkPermissionAccessByCountryOrPlantId(countryId, plantCode, changeMachinePlanDataScope)
        )
      }
    )
    const machinePlanPermissionsTypes = machinePlanPermissions.map(
      (permission) => permission.permissionType
    )

    const canViewMachinePlan = machinePlanPermissionsTypes.includes(
      MachinePlanPermissionTypes.ViewMachinePlan
    )
    const canChangeMachinePlan = machinePlanPermissionsTypes.includes(
      MachinePlanPermissionTypes.ChangeMachinePlan
    )

    return {
      canViewMachinePlan,
      canChangeMachinePlan
    }
  }, [permissions, plantCode, countryId])
}
