import {useUrlParam} from '../../../hooks/useUrlParam'
import {getBaseLoadPowerConsumptionFromHistoryAssets} from '../../../selectors/assets'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryBaseLoadQueryParams {
  timeFrame: MomentRange
}
/**
 * hook to retrieve base load power consumption from assets history
 * NOTE: baseload value would be from the recent history entry in the given time frame
 */
export const useHistoryBaseLoadQuery = ({timeFrame: [from, to]}: HistoryBaseLoadQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const {data: baseLoad} = usePlannerQuery(
    'assetsHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {
      select: (assetsHistory) => getBaseLoadPowerConsumptionFromHistoryAssets(assetsHistory, to)
    }
  )

  return baseLoad ?? 0
}
