import {useMemo} from 'react'

import {usePermissionsQuery} from '../../hooks/api'
import {checkPermissionAccessByCountryOrPlantId} from '../../permissions'
import {usePlantConfig} from '../usePlantConfigData'
import {useUrlParam} from '../useUrlParam'

enum AssetsPermissionTypes {
  ViewAssets = 'VIEW_ASSETS',
  ChangeAssets = 'CHANGE_ASSETS'
}
/**
 * @deprecated
 */

export const useAssetsPermissions = () => {
  const {data: permissions} = usePermissionsQuery()
  const plantCode = useUrlParam('plantCode')
  const latest = usePlantConfig()

  const countryId = latest.country

  return useMemo(() => {
    const assetsPermissions = (permissions || []).filter(
      ({permissionType, parsedDataScope}) =>
        (permissionType === AssetsPermissionTypes.ViewAssets ||
          permissionType === AssetsPermissionTypes.ChangeAssets) &&
        checkPermissionAccessByCountryOrPlantId(countryId, plantCode, parsedDataScope)
    )

    const assetsPermissionTypes = assetsPermissions.map(({permissionType}) => permissionType)

    const canViewAssets = assetsPermissionTypes.includes(AssetsPermissionTypes.ViewAssets)
    const canChangeAssets = assetsPermissionTypes.includes(AssetsPermissionTypes.ChangeAssets)

    return {canViewAssets, canChangeAssets}
  }, [countryId, plantCode, permissions])
}
