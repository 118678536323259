import {PermissionType} from '@hconnect/apiclient/src/types'
import {useMemo} from 'react'

import {usePermissionsQuery} from '../../hooks/api'
import {checkPermissionAccessByCountryOrPlantId} from '../../permissions'
import {usePlantConfig} from '../usePlantConfigData'
import {useUrlParam} from '../useUrlParam'

enum MaterialsPermissionTypes {
  ViewMaterials = 'VIEW_MATERIALS',
  ChangeMaterials = 'CHANGE_MATERIALS'
}
/**
 * @deprecated
 */

export const useMaterialsPermissions = () => {
  const {data: permissions} = usePermissionsQuery()
  const latest = usePlantConfig()

  const plantCode = useUrlParam('plantCode')
  const countryId = latest.country

  return useMemo(() => {
    const materialsPermissions = (permissions || []).filter(({permissionType, parsedDataScope}) => {
      const materialsDataScope = parsedDataScope
      return (
        (
          [
            MaterialsPermissionTypes.ViewMaterials,
            MaterialsPermissionTypes.ChangeMaterials
          ] as PermissionType[]
        ).includes(permissionType) &&
        checkPermissionAccessByCountryOrPlantId(countryId, plantCode, materialsDataScope)
      )
    })

    const materialsPermissionsTypes = materialsPermissions.map(
      (permission) => permission.permissionType
    )

    const canViewMaterials = materialsPermissionsTypes.includes(
      MaterialsPermissionTypes.ViewMaterials
    )
    const canChangeMaterials = materialsPermissionsTypes.includes(
      MaterialsPermissionTypes.ChangeMaterials
    )

    return {canViewMaterials, canChangeMaterials}
  }, [countryId, plantCode, permissions])
}
