import moment, {Moment} from 'moment-timezone'

import {PlannerFeature} from '../../../enums'
import {Range} from '../../../selectors/time'
import {usePlannerFeature} from '../../usePlannerFeature'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface UnplannedDeliveredAmountHourlyParams {
  materialId: number
  timeframe: Range<Moment> | undefined
}

export const useUnplannedDeliveredAmountHourlyQuery = ({
  materialId,
  timeframe
}: UnplannedDeliveredAmountHourlyParams) => {
  const plantCode = useUrlParam('plantCode')
  const [start, end]: (Moment | undefined)[] = timeframe ?? []

  const isRawMaterialsPlanningEnabled = usePlannerFeature(PlannerFeature.RawMaterialsPlanning)
  const isEnabled = Boolean(timeframe) && isRawMaterialsPlanningEnabled

  return usePlannerQuery(
    'materialUnplannedDeliveries',
    [{plantCode, from: start?.toISOString(), to: end?.toISOString(), materialId}],
    {
      enabled: isEnabled,
      select: (unplannedDeliveries) => {
        // TODO extract and test
        const unplannedDeliveriesHourly = unplannedDeliveries.reduce<Record<string, number>>(
          (acc, {deliveredOn, amountDelivered}) => {
            const key = moment.utc(deliveredOn).startOf('hour').toISOString()
            acc[key] = acc[key] ? acc[key] + amountDelivered : amountDelivered
            return acc
          },
          {}
        )
        return unplannedDeliveriesHourly
      }
    }
  )
}
